import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { roles, routes } from "../../../../constants";
import { AmrTrancheStatus, amrTrancheStatusTitles } from "../../../../types/amr-pipeline/enums/AmrTrancheStatus";
import { AmrTransaction } from "../../../../types/amr-pipeline/models/AmrTransaction";
import { OnHoverTooltip } from "../../../common";
import StatusLabel from "../../StatusLabel";
import { startCase } from "lodash";
import { AmrClass } from "../../../../types/amr-pipeline/models/AmrClass";
import Highlighter from "../../common/Highlighter";
import { DealUpdatesPopover } from "../../aggregated/DealUpdatesPopover";
import { user } from "../../../../user";
import { SubscriptionFeature } from "../../../../types/billing/SubscriptionFeature";
import { ActionBlocker } from "../../../access/ActionBlocker";

interface Props {
    transaction: AmrTransaction;
    selected: boolean;
    selectedClassReferenceName?: string;
    searchTerm: string;
}

export const AmrTransactionNavItem = ({
    transaction,
    selected,
    selectedClassReferenceName,
    searchTerm
}: Props) => {
    const history = useHistory();
    const [isCollapsed, setIsCollapsed] = useState(selected ? false : true);
    const isArrangersClient = user.hasSingleRole(roles.ArrangersClient);

    useEffect(() => {
        setIsCollapsed(!selected);
    }, [selected, selectedClassReferenceName]);

    const renderLastUpdatedPopover = (transaction: AmrTransaction) => (
        <ActionBlocker
            feature={SubscriptionFeature.IssuanceMonitorFullAccess}
            overrideRequiredFeatures={isArrangersClient}
        >
            {blocked => {
                const {
                    referenceName,
                    dealReferenceName,
                    dealLegalName,
                    lastUpdated,
                    type,
                    status,
                    version,
                } = transaction;

                return (
                    <DealUpdatesPopover
                        disabled={blocked}
                        withOverlayPrefix
                        historyLinkTarget={selected ? '_self' : '_blank'}
                        referenceName={referenceName}
                        dealReferenceName={dealReferenceName}
                        dealLegalName={dealLegalName}
                        lastUpdated={lastUpdated}
                        type={type}
                        status={status}
                        version={version}
                    />
                );
            }}
        </ActionBlocker>
    );

    const renderClass = (amrClass: AmrClass) => {
        const active = selectedClassReferenceName === amrClass.referenceName;
        const statusVisible =
            amrClass.status === AmrTrancheStatus.Initiated ||
            amrClass.status === AmrTrancheStatus.Scheduled ||
            amrClass.status === AmrTrancheStatus.AcceptingBids;

        const handleClick = (e: React.MouseEvent) => {
            redirectToClass();
        };

        const redirectToClass = () =>
            history.push(
                routes.transactionClassUrl(
                    transaction.referenceName,
                    transaction.dealReferenceName,
                    amrClass.referenceName
                )
            );

        return (
            amrClass?.status !== AmrTrancheStatus.Initiated &&
            <li
                key={amrClass.referenceName}
                onClick={handleClick}
                className={classNames("component-subtree-item", { active })}
            >
                <span className="component-subtree-item-content">
                    <span className="component-subtree-item-title">
                        Class {amrClass.name}
                    </span>
                    {statusVisible && <StatusLabel status={amrTrancheStatusTitles[amrClass.status]} />}
                </span>
            </li>
        );
    };
    return (
        <>
            <div
                className={classNames(
                    "sidebar-nav-list-item sidebar-nav-list-item-extended",
                    {
                        active: selected,
                        expanded: !isCollapsed,
                    }
                )}
            >
                <i
                    onClick={() =>
                        selected
                            ? setIsCollapsed(!isCollapsed)
                            : history.push(
                                routes.transactionDetailUrl(
                                    transaction.referenceName,
                                    transaction.dealReferenceName
                                )
                            )
                    }
                    className="icon icon-expand"
                ></i>
                <div
                    className="sidebar-nav-list-item-content"
                    onClick={() => history.push(
                        routes.transactionDetailUrl(
                            transaction.referenceName,
                            transaction.dealReferenceName
                        )
                    )}
                >
                    <div className="flex-row">
                        <Highlighter
                            searchWords={[searchTerm]}
                            autoEscape={true}
                            textToHighlight={transaction.dealLegalName}
                            className="title"
                        />
                        <div className="flex-item-right">
                            {transaction.isNew && <div className="state-new">new</div>}
                        </div>
                    </div>
                    <div className="flex-row">
                        <OnHoverTooltip wrapperClass="flex-none item-info-tooltip" overlay={`Transaction Status: ${transaction.status}`}>
                            <StatusLabel status={transaction.status} />
                        </OnHoverTooltip>

                        <OnHoverTooltip wrapperClass="flex-none item-info-tooltip" overlay={`Transaction Type: ${startCase(transaction.type)}`}>
                            <div
                                className={`label-status label-status-type-${startCase(transaction.type)
                                    .toLowerCase()}`}
                            >
                                {transaction.type}
                            </div>
                        </OnHoverTooltip>
                        <div className="item-info-tooltip text-right">
                            {renderLastUpdatedPopover(transaction)}
                        </div>
                    </div>
                </div>
            </div>
            <ul className="component-subtree">
                {selected &&
                    !isCollapsed &&
                    transaction.classes.map((c) => renderClass(c))}
            </ul>
        </>
    );
};
