import { useState } from "react";
import IconSVG from "../../../styles/svg-icons";
import { constants, routes } from "../../../constants";
import { ClickOutside, OnHoverTooltip, Preloader } from "../../common";
import { useDealTransactions } from "../../../effects/useDealTransactions";
import classNames from "classnames";
import { Table } from "../../bidding/common/table";
import { isRequesting } from "../../../utils";
import { compareDates } from "../../../utils/compare.utils";
import { Transaction } from "../../../types/amr-pipeline/models/Transaction";
import { IColumnDefinition } from "../../bidding/common/table/types/ColumnDefinition";
import moment from "moment";
import { startCase } from "lodash";
import { ColumnBuilder } from "../../bidding/common/table/columns/column-builder/ColumnBuilder";

const renderRelatedTransIcon = (isExpanded = false, disabled = false) => (
    <button className={classNames("btn-link related-trans-btn", { disabled } )}>
        <IconSVG name="related-transaction" width={16} height={16} />
        Related Trans
        <IconSVG
            name="corner-arrow"
            width={16}
            height={16}
            className={classNames("icon-component-dropdown", {
                "is-open": isExpanded,
            })}
        />
    </button>
);

const renderDisabledButton = () => (
    <OnHoverTooltip overlay="There are no related transactions">
        {renderRelatedTransIcon(false, true)}
    </OnHoverTooltip>
);

interface Props {
    dealReferenceName: string;
    dealLegalName: string;
    hasTransactions: boolean;
    disabled?: boolean;
}

const relatedTransColumnDefinitions: IColumnDefinition<Transaction | null>[] = [
    {
        columnKey: "type",
        renderColumnHeaderContent: () => "Trans. Type",
        renderColumnContent: (transaction) =>
            transaction?.type ? (
                startCase(transaction.type)
            ) : (
                <span className="text-medium">All Trans</span>
            ),
    },
    {
        columnKey: "closingDate",
        renderColumnHeaderContent: () => "Closing",
        renderColumnContent: (transaction) =>
            transaction?.closingDate
                ? moment(transaction.closingDate).format(constants.dateFormat)
                : constants.emptyPlaceholder,
    },
    {
        columnKey: "icon",
        renderColumnHeaderContent: () => "",
        renderColumnContent: () => <IconSVG name="go-to" width={16} height={16} />,
        className: "data-list-cell-xxxs"
    },
];

const relatedColumns = relatedTransColumnDefinitions.map((c) => {
    return new ColumnBuilder(c);
});

export function RelatedTransDropdown({ dealLegalName, dealReferenceName, hasTransactions, disabled }: Props) {
    const [expanded, setExpanded] = useState(false);
    const { dealTransactions, requestState } = useDealTransactions(
        dealReferenceName,
        expanded
    );

    if (disabled) {
        return renderRelatedTransIcon(false, disabled);
    }

    if (!hasTransactions) {
        return renderDisabledButton();
    }

    const sortedTrans =
        dealTransactions?.sort((a, b) =>
            compareDates(b.closingDate, a.closingDate)
        ) || [];

    const onRowClick = (transaction?: Transaction) => {
        const transactionUrl = transaction
            ? routes.transactionDetailUrl(
                  transaction.referenceName,
                  transaction.dealReferenceName
              )
            : `${routes.AMRPipeline}/?dealsReferenceNames=${dealReferenceName}&dealsLegalNames=${dealLegalName}`;

        window.open(transactionUrl);
    };

    return (
        <ClickOutside
            className="component-dropdown component-dropdown-related-trans"
            onClick={() => setExpanded(false)}
        >
            <div
                className={classNames("component-dropdown-toggle", {
                    "component-dropdown-toggle-selected": expanded,
                })}
                onClick={() => setExpanded(!expanded)}
            >
                {renderRelatedTransIcon(expanded)}
            </div>
            {expanded && (
                <div className="component-dropdown-list">
                    <Preloader inProgress={isRequesting(requestState)} small>
                        <Table
                            className="data-list-striped data-list-share-transaction"
                            dataItems={[null, ...sortedTrans]}
                            columns={relatedColumns}
                            onRowClick={onRowClick}
                        />
                    </Preloader>
                </div>
            )}
        </ClickOutside>
    );
}

