import { routes } from '../../../constants';
import { OnHoverTooltip } from '../../common';
import IconSVG from '../../../styles/svg-icons';
import { FeatureLink } from '../../access/FeatureLink';
import { ActionBlocker } from '../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';

interface Props {
    dealReferenceName: string;
    dealLegalName: string;
    children?: React.ReactNode;
}

export function DealLookupLink({ dealReferenceName, dealLegalName, children }: Props) {
    return (
        <ActionBlocker feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
            {
                blocked =>
                    <OnHoverTooltip overlay={!blocked && "Related Transactions"}>
                        <FeatureLink
                            className="btn-link"
                            path={`${routes.AMRPipeline}?dealsReferenceNames=${dealReferenceName}&dealsLegalNames=${dealLegalName}`}
                            target="_blank"
                        >
                            <IconSVG name="related-transaction" width={16} height={16} />
                            <>{children}</>
                        </FeatureLink>
                    </OnHoverTooltip>
            }
        </ActionBlocker>
    );
}