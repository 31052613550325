import moment from 'moment';
import { pipelineColumnLabels, pipelineTooltipText, SORTING_TYPE } from '../../constants';
import { constants } from '../../constants/constants';
import IconSVG from '../../styles/svg-icons';
import { formatUtils } from '../../utils';
import { PeriodPopover } from '../amrPipeline/aggregated/PeriodPopover';
import { PipelineColumn } from '../amrPipeline/types/PipelineColumn';
import { IColumnDefinition } from '../bidding/common/table/types/ColumnDefinition';
import { OnHoverTooltip } from '../common';
import { MarkdownViewer, renderMarkdownOrEmpty } from '../common/markdown-editor';
import { Popover } from '../controls';
import { DataFromAmr } from './types/DataFromAmr';
import { ManagerLink } from './common/ManagerLink';
import { ColumnConfigKey } from '../../types/page-config/column-customizer/ColumnConfigKey';
import { bwicColumnsLabels } from '../../constants/bwicColumnsLabels';


enum CommonAmrColumns {
    dealName = 'dealName',
    manager = 'manager',
    nonCallPeriodEnd = 'nonCallPeriodEnd',
    reinvestmentPeriodEnd = 'reinvestmentPeriodEnd',
    nonCallPeriodEndYears = 'nonCallPeriodEndYears',
    reinvestmentPeriodEndYears = 'reinvestmentPeriodEndYears',
    maturity = 'maturity',
    vintage = 'vintage',
    trustee = 'trustee',
    amrDeal = 'amrDeal',
    staticDeal = 'staticDeal',
    euCompliance = 'euCompliance',
    esg = 'esg',
    collateralType = 'collateralType',
    closingDate = 'closingDate',
    outOfNC = 'outOfNC',
    outOfRI = 'outOfRI'
}

export function getAmrColumns(): { [key in CommonAmrColumns]: IColumnDefinition<DataFromAmr> } {
    return {
        [PipelineColumn.dealName]: {
            columnKey: PipelineColumn.dealName,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.dealName],
            renderColumnContent: ({ dealLegalName }) => {
                return dealLegalName ? (
                    <OnHoverTooltip overlay={dealLegalName}>{dealLegalName}</OnHoverTooltip>
                ) : (
                    constants.emptyPlaceholder
                );
            },
            sortingField: 'dealLegalName',
            sortingType: SORTING_TYPE.string,
            className: 'data-list-cell-xl',
            configKey: ColumnConfigKey.DealName
        },
        [PipelineColumn.manager]: {
            columnKey: PipelineColumn.manager,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.manager],
            renderColumnContent: ({ collateralManager }) => {
                if (collateralManager) {
                    return (
                        <ManagerLink
                            referenceName={collateralManager.referenceName}
                            legalName={collateralManager.legalName}
                        />
                    )
                }
                return constants.emptyPlaceholder
            },
            sortingField: 'collateralManager',
            sortingType: SORTING_TYPE.company,
            headerClassName: 'data-list-cell-xl',
            bodyClassName: 'data-list-cell-xl',
            configKey: ColumnConfigKey.CollateralManager
        },
        [PipelineColumn.closingDate]: {
            columnKey: PipelineColumn.closingDate,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.closingDate],
            renderColumnContent: ({ closingDate }) =>
                closingDate ? moment(closingDate).format(constants.dateFormat) : constants.emptyPlaceholder,
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-sm',
            sortingType: SORTING_TYPE.date,
            sortingField: PipelineColumn.closingDate,
            configKey: ColumnConfigKey.ClosingDate
        },
        [PipelineColumn.nonCallPeriodEnd]: {
            columnKey: 'ncEnd',
            configKey: ColumnConfigKey.NcEnd,
            renderColumnHeaderContent: () => bwicColumnsLabels[ColumnConfigKey.NcEnd],
            renderColumnContent: ({ nonCallPeriodEnd }) => 
                nonCallPeriodEnd ? moment(nonCallPeriodEnd).format('MMM DD, YYYY'): constants.emptyPlaceholder,
            className: 'data-list-cell-sm',
        },
        [PipelineColumn.nonCallPeriodEndYears]: {
            columnKey: PipelineColumn.nonCallPeriodEnd,
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.nonCallPeriodEnd]}>
                    {pipelineColumnLabels[PipelineColumn.nonCallPeriodEndYears]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            ),
            renderColumnContent: ({ nonCallPeriodEnd, reinvestmentPeriodEnd, nonCallPeriodEndYears }) => (
                <>
                    <OnHoverTooltip
                        overlay={
                            <PeriodPopover
                                reinvestmentPeriodEnd={reinvestmentPeriodEnd}
                                nonCallPeriodEnd={nonCallPeriodEnd}
                            />
                        }
                    >
                        <span>
                            {nonCallPeriodEndYears?.toFixed(2) ?? constants.emptyPlaceholder}
                        </span>
                    </OnHoverTooltip>
                </>
            ),
            sortingField: PipelineColumn.nonCallPeriodEndYears,
            sortingType: SORTING_TYPE.number,
            headerClassName: 'data-list-cell-sm text-right',
            bodyClassName: 'data-list-cell-sm text-right popover-column',
            configKey: ColumnConfigKey.NcEndYrs
        },
        [PipelineColumn.outOfNC]: {
            columnKey: PipelineColumn.outOfNC,
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfNC]}>
                    {pipelineColumnLabels[PipelineColumn.outOfNC]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            ),
            renderColumnContent: ({ reinvestmentPeriodEnd, nonCallPeriodEnd, outOfNC }) => {
                const columnText = outOfNC == null
                    ? constants.emptyPlaceholder
                    : formatUtils.formatBoolean(outOfNC)
                return (
                    <OnHoverTooltip
                        overlay={
                            <PeriodPopover
                                reinvestmentPeriodEnd={reinvestmentPeriodEnd}
                                nonCallPeriodEnd={nonCallPeriodEnd}
                            />
                        }
                    >
                        {columnText}
                    </OnHoverTooltip>
                );
            },
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-sm popover-column text-capitalize',
            configKey: ColumnConfigKey.OutOfNc
        },
        [PipelineColumn.reinvestmentPeriodEnd]: {
            columnKey: 'riEnd',
            configKey: ColumnConfigKey.RiEnd,
            renderColumnHeaderContent: () => bwicColumnsLabels[ColumnConfigKey.RiEnd],
            renderColumnContent: ({ reinvestmentPeriodEnd }) =>
                reinvestmentPeriodEnd ? moment(reinvestmentPeriodEnd).format('MMM DD, YYYY') : constants.emptyPlaceholder,
            className: 'data-list-cell-sm',
        },
        [PipelineColumn.reinvestmentPeriodEndYears]: {
            columnKey: PipelineColumn.reinvestmentPeriodEnd,
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.reinvestmentPeriodEnd]}>
                    {pipelineColumnLabels[PipelineColumn.reinvestmentPeriodEndYears]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            ),
            renderColumnContent: ({ reinvestmentPeriodEnd, nonCallPeriodEnd, reinvestmentPeriodEndYears }) => (
                <>
                    <OnHoverTooltip
                        overlay={
                            <PeriodPopover
                                reinvestmentPeriodEnd={reinvestmentPeriodEnd}
                                nonCallPeriodEnd={nonCallPeriodEnd}
                            />
                        }
                    >
                        <span>
                            {reinvestmentPeriodEndYears?.toFixed(2) ?? constants.emptyPlaceholder}
                        </span>
                    </OnHoverTooltip>
                </>
            ),
            sortingField: PipelineColumn.reinvestmentPeriodEndYears,
            sortingType: SORTING_TYPE.number,
            headerClassName: 'data-list-cell-sm text-right',
            bodyClassName: 'data-list-cell-sm text-right popover-column',
            configKey: ColumnConfigKey.RiEndYrs
        },
        [PipelineColumn.outOfRI]: {
            columnKey: PipelineColumn.outOfRI,
            renderColumnHeaderContent: () => (
                <OnHoverTooltip overlay={pipelineTooltipText[PipelineColumn.outOfRI]}>
                    {pipelineColumnLabels[PipelineColumn.outOfRI]}
                    <IconSVG name="info" width={16} height={16} />
                </OnHoverTooltip>
            ),
            renderColumnContent: ({ reinvestmentPeriodEnd, nonCallPeriodEnd, outOfRI }) => {
                const columnText = outOfRI == null
                    ? constants.emptyPlaceholder
                    : formatUtils.formatBoolean(outOfRI)
                return (
                    <OnHoverTooltip
                        overlay={
                            <PeriodPopover
                                reinvestmentPeriodEnd={reinvestmentPeriodEnd}
                                nonCallPeriodEnd={nonCallPeriodEnd}
                            />
                        }
                    >
                        {columnText}
                    </OnHoverTooltip>
                );
            },
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-sm popover-column text-capitalize',
            configKey: ColumnConfigKey.OutOfRi
        },
        [PipelineColumn.maturity]: {
            columnKey: PipelineColumn.maturity,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.maturity],
            renderColumnContent: ({ statedMaturity, finalMaturity }) => {
                const maturity = statedMaturity || finalMaturity;
                return maturity ? moment(maturity).format(constants.dateFormat) : constants.emptyPlaceholder;
            },
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-sm',
            sortingField: 'statedMaturity',
            sortingType: SORTING_TYPE.date,
            configKey: ColumnConfigKey.Maturity
        },
        [PipelineColumn.vintage]: {
            columnKey: PipelineColumn.vintage,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.vintage],
            renderColumnContent: ({ vintage }) => {
                return vintage ? moment(vintage).year() : constants.emptyPlaceholder;
            },
            sortingField: PipelineColumn.vintage,
            sortingType: SORTING_TYPE.date,
            configKey: ColumnConfigKey.Vintage
        },
        [PipelineColumn.trustee]: {
            columnKey: PipelineColumn.trustee,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.trustee],
            renderColumnContent: ({ trustee }) => {
                if (trustee) {
                    return <OnHoverTooltip overlay={trustee.legalName}>{trustee.legalName}</OnHoverTooltip>;
                }

                return constants.emptyPlaceholder;
            },
            headerClassName: 'data-list-cell-xl',
            bodyClassName: 'data-list-cell-xl',
            sortingField: PipelineColumn.trustee,
            sortingType: SORTING_TYPE.company,
            configKey: ColumnConfigKey.Trustee
        },
        [PipelineColumn.amrDeal]: {
            columnKey: PipelineColumn.amrDeal,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.amrDeal],
            renderColumnContent: ({ amr }) => formatUtils.formatBooleanWithPlaceholder(amr),
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-sm',
            sortingField: 'amr',
            sortingType: SORTING_TYPE.boolean,
            configKey: ColumnConfigKey.AmrDeal
        },
        [PipelineColumn.staticDeal]: {
            columnKey: PipelineColumn.staticDeal,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.staticDeal],
            renderColumnContent: transaction => formatUtils.formatBooleanWithPlaceholder(transaction.staticDeal),
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-sm',
            sortingField: PipelineColumn.staticDeal,
            sortingType: SORTING_TYPE.boolean,
            configKey: ColumnConfigKey.StaticDeal
        },
        [PipelineColumn.euCompliance]: {
            columnKey: PipelineColumn.euCompliance,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.euCompliance],
            renderColumnContent: ({
                dealLegalName,
                euCompliance,
                euRiskRetentionRequirements,
                euDisclosureRequirements,
            }) => {
                const columnText = formatUtils.formatBooleanWithPlaceholder(euCompliance);

                if (euCompliance && (euRiskRetentionRequirements || euDisclosureRequirements)) {
                    return (
                        <Popover
                            title="EU Compliance"
                            className="popover-eu-compliance"
                            label={
                                <OnHoverTooltip overlay={dealLegalName}>
                                    <span className="name-tag">{dealLegalName}</span>
                                </OnHoverTooltip>
                            }
                            actionButton={<button className="pseudo-link">{columnText}</button>}
                        >
                            <h2 className="title-border">EU Risk Retention Requirements</h2>
                            <div className="popover-text-box">{renderMarkdownOrEmpty(euRiskRetentionRequirements)}</div>
                            <h2 className="title-border">EU Disclosure Requirements</h2>
                            <div>{renderMarkdownOrEmpty(euDisclosureRequirements)}</div>
                        </Popover>
                    );
                }

                return columnText;
            },
            headerClassName: 'data-list-cell-sm',
            bodyClassName: 'data-list-cell-sm',
            sortingField: PipelineColumn.euCompliance,
            sortingType: SORTING_TYPE.boolean,
            configKey: ColumnConfigKey.EuCompliance
        },
        [PipelineColumn.esg]: {
            columnKey: PipelineColumn.esg,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.esg],
            renderColumnContent: ({ dealLegalName, esg, esgComment }) => {
                const columnText = formatUtils.formatBooleanWithPlaceholder(esg);

                if (esg && esgComment) {
                    return (
                        <Popover
                            title="ESG Comment"
                            label={
                                <OnHoverTooltip overlay={dealLegalName}>
                                    <span className="name-tag text-ellipsis">{dealLegalName}</span>
                                </OnHoverTooltip>
                            }
                            actionButton={<button className="pseudo-link">{columnText}</button>}
                            className="popover-esg-comments"
                        >
                            <MarkdownViewer value={esgComment} />
                        </Popover>
                    );
                }

                return columnText;
            },
            headerClassName: 'data-list-cell-xs',
            bodyClassName: 'data-list-cell-xs',
            sortingField: PipelineColumn.esg,
            sortingType: SORTING_TYPE.boolean,
            configKey: ColumnConfigKey.Esg
        },
        [PipelineColumn.collateralType]: {
            columnKey: PipelineColumn.collateralType,
            renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.collateralType],
            renderColumnContent: ({ collateralType }) => formatUtils.formatCollateralType(collateralType),
            headerClassName: 'data-list-cell-xs',
            bodyClassName: 'data-list-cell-xs',
            sortingField: PipelineColumn.collateralType,
            sortingType: SORTING_TYPE.string,
            configKey: ColumnConfigKey.CollateralType
        },
    };
}
