import { amrPipelineDetailedActions } from "../../../../actions";
import { AmrDocument } from "../../../../types/amr-pipeline/models/AmrDocument";
import { OriginatingTransactionDocument } from "../../../../types/amr-pipeline/models/OriginatingTransactionDocument";
import { Table } from "../../../bidding/common/table";
import { dealDocumentsColumns } from "./documents-columns";
import { AmrFile } from "../../../../types/amr-pipeline/models/AmrFile";
import {
    DifferenceType,
    ObjectArrayDifference,
    ObjectArrayItemDifference,
} from "../../../../utils/differ/types";
import {
    DifferenceListProps,
    withDeletedItems,
} from "../../detailed/new-issue/withDeletedItems";
import { useAppDispatch } from "../../../../effects/useAppDispatch";
import { DocumentGroup } from "./DocumentGroup";

type TransactionDocument = AmrDocument | OriginatingTransactionDocument;

interface DocumentGroupProps
    extends DifferenceListProps<TransactionDocument, TransactionDocument> {
    title: string;
    dealReferenceName: string;
    transactionReferenceName?: string;
    items: TransactionDocument[];
    difference?: ObjectArrayDifference<TransactionDocument[]>;
    downloadAllAction: () => void;
    emptyPlaceholder?: string;
}

function isRemoved(
    documentDifference:
        | ObjectArrayItemDifference<TransactionDocument>
        | undefined
) {
    return documentDifference?.type === DifferenceType.Removed;
}

function Component({
    dealReferenceName,
    transactionReferenceName,
    title,
    items,
    difference,
    downloadAllAction,
    emptyPlaceholder,
}: DocumentGroupProps) {
    const dispatch = useAppDispatch();
    const documentsWithLastDate = items.map((d) => ({
        ...d,
        lastDate: d.executionDate ?? d.uploadTime,
    }));

    function onRowClick(document: AmrFile) {
        const documentDifference = getDifference(document.referenceName);
        if (isRemoved(documentDifference)) {
            return null;
        }

        transactionReferenceName
            ? dispatch(
                  amrPipelineDetailedActions.downloadTransactionDocument(
                      dealReferenceName,
                      transactionReferenceName,
                      document.referenceName,
                      document.name
                  )
              )
            : dispatch(
                  amrPipelineDetailedActions.downloadDealDocument(
                      dealReferenceName,
                      document.referenceName,
                      document.name
                  )
              );
    }

    function getDifference(referenceName: string) {
        // Find array item difference by item reference name
        // Default currentValue with previousValue for deleted documents
        return difference?.find(
            ({ derivedValue }) => derivedValue?.referenceName === referenceName
        );
    }

    return (
        <DocumentGroup
            title={title}
            itemsCount={documentsWithLastDate.length}
            downloadAll={downloadAllAction}
            emptyPlaceholder={emptyPlaceholder}
        >
            <Table
                className="component-file-upload-list data-list-striped"
                dataItems={documentsWithLastDate}
                onRowClick={onRowClick}
                columns={dealDocumentsColumns()}
                createSecurityCustomArgs={(
                    item: AmrDocument | OriginatingTransactionDocument
                ) => {
                    const itemDifference = getDifference(item.referenceName);
                    const isDocRemoved = isRemoved(itemDifference);

                    return {
                        dealReferenceName,
                        handleClick: isDocRemoved ? () => null : onRowClick,
                        difference: itemDifference,
                        isRemoved: isDocRemoved,
                    };
                }}
                createSecurityCustomClassName={(
                    item: AmrDocument | OriginatingTransactionDocument
                ) => {
                    const itemDifference = getDifference(item.referenceName);
                    if (isRemoved(itemDifference)) {
                        //NOTE FOR MARKUP DEVS. Please, create class for disabled document rows
                        return "disabled";
                    }
                }}
            />
        </DocumentGroup>
    );
}

export const DocumentSection = withDeletedItems(Component);
