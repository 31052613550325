import { FileIcon, Popup, PopupBody, PopupFooter } from "../../controls/";
import { constants } from "../../../constants";
import { OriginatingTransaction } from "../../../types/amr-pipeline/models/OriginatingTransaction";
import { FormField } from "../common/FormField";
import DownloadDocument from "../common/documents/DownloadDocument";
import { amrPipelineDetailedActions } from "../../../actions";
import { getCurrentIntexPositionIdForTransaction, transactionHasArrangerProSubscription } from "../../../utils/amr-pipeline.utils";
import { IntexLink } from "../../common/IntexLink";
import { SectionContent } from "../common/section/SectionContent";
import { ValueBlocker } from '../../access/ValueBlocker';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { RequiredFeature } from '../../access/RequiredFeature';
import { SubscribeLink } from '../../access/BlockedFeatureText';
import { useAppDispatch } from "../../../effects/useAppDispatch";

interface IntexPopupProps {
    transaction: OriginatingTransaction;
    omitArrangerProOverride?: boolean;
    onCloseClick: () => void;
}

export const IntexPopup = ({
    transaction,
    omitArrangerProOverride,
    onCloseClick,
}: IntexPopupProps) => {
    const dispatch = useAppDispatch();
    const {
        dealReferenceName,
        referenceName,
        intexName,
        intexPassword,
        intexFile,
        dealTicker,
        dealLegalName,
        intexExists,
    } = transaction;

    const currentIntexPositionId = getCurrentIntexPositionIdForTransaction(transaction);
    const hasArrangerProSubscription = transactionHasArrangerProSubscription(transaction);

    const renderFileValue = () => {
        if (!intexFile && !intexExists) {
            return constants.emptyPlaceholder;
        }

        return (
            <RequiredFeature
                inline
                feature={SubscriptionFeature.IssuanceMonitorStandardizedDetailsTabs}
                text={<><SubscribeLink /> to download Intex file</>}
                overrideRequiredFeatures={omitArrangerProOverride ? false : hasArrangerProSubscription}
            >
                {intexFile ? (
                    <DownloadDocument
                        onClick={
                            () => dispatch(
                                amrPipelineDetailedActions.downloadIntexFile(
                                    dealReferenceName,
                                    referenceName,
                                    intexFile.name
                                )
                            )
                        }
                        document={transaction.intexFile}
                        secondary
                    >
                        <FileIcon filename={transaction.intexFile.name} />
                        <span>
                            {transaction.intexFile.name}
                        </span>

                    </DownloadDocument>
                ) : constants.emptyPlaceholder}
            </RequiredFeature>
        );
    };

    return (
        <Popup
            title={
                <>
                    <span className="title">Intex</span>
                    <span className="name-tag text-ellipsis">{dealLegalName}</span>
                </>
            }
            modalClass="intex-popup"
            onClose={onCloseClick}
        >
            <PopupBody>
                <SectionContent
                    title="Pre-Price"
                    actionBtn={
                        <IntexLink
                            positionId={intexName}
                            text="Open Intex"
                            overrideRequiredFeature={hasArrangerProSubscription}
                        />
                    }
                >
                    <FormField
                        title="Deal Name"
                        value={
                            <ValueBlocker
                                feature={SubscriptionFeature.IssuanceMonitorStandardizedDetailsTabs}
                                overrideRequiredFeatures={omitArrangerProOverride ? !intexExists : !intexExists || hasArrangerProSubscription}
                            >
                                {intexName || constants.emptyPlaceholder}
                            </ValueBlocker>
                        }
                        className="data-item-row-full-height data-item-row-title-sm"
                    />
                    <FormField
                        title="Password"
                        value={
                            <ValueBlocker
                                feature={SubscriptionFeature.IssuanceMonitorStandardizedDetailsTabs}
                                overrideRequiredFeatures={omitArrangerProOverride ? !intexExists : !intexExists || hasArrangerProSubscription}
                            >
                                {intexPassword || constants.emptyPlaceholder}
                            </ValueBlocker>
                        }
                        className="data-item-row-full-height data-item-row-title-sm"
                    />
                    <FormField
                        title="File"
                        value={renderFileValue()}
                        className="file-download-row data-item-row-title-sm"
                    />
                </SectionContent>
                <SectionContent
                    title="Current"
                    actionBtn={
                        <IntexLink
                            positionId={currentIntexPositionId}
                            text="Open Intex"
                            overrideRequiredFeature={hasArrangerProSubscription}
                        />
                    }
                >
                    <FormField
                        title="Ticker"
                        value={dealTicker || constants.emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-sm"
                    />
                </SectionContent>
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={onCloseClick}>
                    Close
                </button>
            </PopupFooter>
        </Popup>
    );
};

export default IntexPopup;
