import * as React from "react";
import { EmptySectionContent } from "../section/EmptySectionContent";
import IconSVG from "../../../../styles/svg-icons";

interface Props {
    title: string;
    itemsCount?: number;
    children: React.ReactNode;
    downloadAll: () => void;
    emptyPlaceholder?: string;
}

export function DocumentGroup({
    title,
    itemsCount = 0,
    children,
    downloadAll,
    emptyPlaceholder,
}: Props) {
    const [collapsed, setCollapsed] = React.useState(false);

    return (
        <div className="document-group-item">
            <div className="document-group-item-title">
                <div
                    className="document-group-click-area"
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <IconSVG
                        name="corner-arrow"
                        className={`${!collapsed ? "is-open" : ""}`}
                        width={16}
                        height={16}
                    />
                    <h2>
                        {title}
                        <span className="header-title-count">{itemsCount}</span>
                    </h2>
                </div>
                {!!itemsCount && (
                    <div className="flex-item-right">
                        <a
                            className="btn-link link-download-docs"
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                downloadAll();
                            }}
                        >
                            <IconSVG
                                name="downloadTemplate"
                                width={16}
                                height={16}
                            />
                            <span className="align-middle">Download All</span>
                        </a>
                    </div>
                )}
            </div>
            {!collapsed && (
                <div className="data-item-documents">
                    {itemsCount || !emptyPlaceholder ? (
                        children
                    ) : (
                        <EmptySectionContent text={emptyPlaceholder} />
                    )}
                </div>
            )}
        </div>
    );
}
