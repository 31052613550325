import { useContext, useEffect } from "react";
import { amrPipelineDetailedActions } from "../../../../actions";
import { roles, routes } from "../../../../constants";
import { OriginatingTransaction } from "../../../../types/amr-pipeline/models/OriginatingTransaction";
import { TabList, TabItem } from "../../../bidding/common/tab-list";
import { TransactionsTabTypes } from "../../types/TransactionsTabTypes";
import { Documents } from "./documents/Documents";
import { DealOverview } from "./deal-overview/DealOverview";
import { DealStructure } from "./deal-structure/DealStructure";
import { TargetPortfolio } from "./target-portfolio/TargetPortfolio";
import { Preloader } from "../../../common";
import { TabContent } from "../../common/tabs/TabContent";
import { IOIs } from "./ioi/IOIs";
import { Analytics } from "./analytics/Analytics";
import { IOI } from "../../../../types/amr-pipeline/models/IOI";
import { user } from "../../../../user";
import { Difference } from '../../../../utils/differ/types';
import { OriginatingTransactionDiff } from '../../../../types/amr-pipeline/models/OriginatingTransactionDiff';
import { useHistory } from 'react-router';
import TransactionContext from '../../transactionContext';
import { PipelineDetailedTabTypes } from "../../types/PipelineDetailedTabTypes";
import { InvitedClients } from "./invited-clients/InvitedClients";
import { TransactionStatus } from "../../../../types/amr-pipeline/enums/TransactionStatus";
import { useAppDispatch } from "../../../../effects/useAppDispatch";

interface Props {
    transaction: OriginatingTransaction;
    difference?: Difference<OriginatingTransactionDiff>;
    activeTab?: TransactionsTabTypes;
    activeSelectionPanelTab?: PipelineDetailedTabTypes;
    disabled?: boolean;
    isLoading: boolean;
    onNavigate?: (tab: string) => void;
}

export const OriginatingTransactionDetails = ({
    transaction,
    difference,
    activeTab = TransactionsTabTypes.overview,
    activeSelectionPanelTab = PipelineDetailedTabTypes.all,
    disabled,
    isLoading,
    onNavigate,
}: Props) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { limitedAccess, analyticsAccess, invitedClientsAccess, ioisAccess } = useContext(TransactionContext);

    const isMedia = user.hasRoles(roles.Media);

    const { referenceName, dealReferenceName } = transaction;
    const isOriginatingWithoutHistory = !difference;
    const { iois, status, documents, isDetailsLoaded } = transaction;
    const isTransactionNonDraft = status !== TransactionStatus.Draft;

    const isSeller = user.hasRoles(roles.SellerTrader);
    const unreadIOIs = iois && !isSeller ? iois.filter((ioi: IOI) => !ioi.isRead).length : null;

    const renderActiveTab = () => {
        if (activeTab === TransactionsTabTypes.structure) {
            return (
                <DealStructure transaction={transaction} difference={difference} />
            );
        }

        if (activeTab === TransactionsTabTypes.portfolio && !limitedAccess && !isMedia) {
            return (
                <TargetPortfolio isLoading={isLoading} transaction={transaction} difference={difference} />
            );
        }

        if (activeTab === TransactionsTabTypes.documents && documents && !limitedAccess && !isMedia) {
            return (
                 <Documents transaction={transaction} difference={difference} />
            );
        }

        if (activeTab === TransactionsTabTypes.IOIs && iois && ioisAccess) {
            return (
                <IOIs
                    transaction={transaction}
                    activeTab={activeSelectionPanelTab}
                    isLoading={isLoading}
                />
            )
        }

        if (activeTab === TransactionsTabTypes.analytics && analyticsAccess) {
            return (
                <Analytics
                    transaction={transaction}
                    isLoading={isLoading}
                />
            )
        }

        if (activeTab === TransactionsTabTypes.invitedClients && invitedClientsAccess) {
            return (
                <InvitedClients
                    transaction={transaction}
                    isLoading={isLoading}
                />
            )
        }

        return (
            <DealOverview transaction={transaction} difference={difference} />
        );

    };

    const handleTabChange = (tab: string) => {
        if (onNavigate) {
            return onNavigate(tab);
        }

        return history.replace(routes.transactionDetailUrl(referenceName, dealReferenceName, tab));
    };

    useEffect(() => {
        // History mode — do not load details
        if (difference) {
            return;
        }

        dispatch(
            amrPipelineDetailedActions.loadTransactionDetails(
                dealReferenceName,
                referenceName,
                isOriginatingWithoutHistory
            )
        );
    }, [
        dispatch,
        dealReferenceName,
        referenceName,
        isOriginatingWithoutHistory,
        transaction,
        difference,
    ]);

    return (
        <>
            <TabList className="tabs-left-shift">
                <TabItem
                    active={activeTab === TransactionsTabTypes.overview}
                    title="Overview"
                    onClick={() => handleTabChange(TransactionsTabTypes.overview)}
                    disabled={
                        (disabled && activeTab !== TransactionsTabTypes.overview)
                    }
                />
                <TabItem
                    active={activeTab === TransactionsTabTypes.structure}
                    title="Structure"
                    onClick={() => handleTabChange(TransactionsTabTypes.structure)}
                    disabled={
                        (disabled && activeTab !== TransactionsTabTypes.structure)
                    }
                />
                {!limitedAccess && !isMedia && (
                    <TabItem
                        active={activeTab === TransactionsTabTypes.portfolio}
                        title="Target Portfolio"
                        onClick={() => handleTabChange(TransactionsTabTypes.portfolio)}
                        disabled={
                            (disabled && activeTab !== TransactionsTabTypes.portfolio)
                        }
                    />
                )}
                {!limitedAccess && !isMedia && (
                    <TabItem
                        active={activeTab === TransactionsTabTypes.documents}
                        title="Documents"
                        onClick={() => handleTabChange(TransactionsTabTypes.documents)}
                        disabled={
                            (disabled && activeTab !== TransactionsTabTypes.documents)
                        }
                    />
                )}

                {ioisAccess && isOriginatingWithoutHistory && isTransactionNonDraft && (
                    <TabItem
                        active={activeTab === TransactionsTabTypes.IOIs}
                        title={
                            <span>
                                IOIs
                                {unreadIOIs ? <span className="badge" /> : null}
                            </span>
                        }
                        onClick={() => handleTabChange(TransactionsTabTypes.IOIs)}
                        disabled={disabled && activeTab !== TransactionsTabTypes.IOIs}
                    />
                )}
                {analyticsAccess && isOriginatingWithoutHistory && (
                    <TabItem
                        active={activeTab === TransactionsTabTypes.analytics}
                        title="Analytics"
                        onClick={() => handleTabChange(TransactionsTabTypes.analytics)}
                        disabled={disabled && activeTab !== TransactionsTabTypes.analytics}
                    />
                )}
                {invitedClientsAccess && isOriginatingWithoutHistory && (
                    <TabItem
                        active={activeTab === TransactionsTabTypes.invitedClients}
                        title="Invited Clients"
                        onClick={() => handleTabChange(TransactionsTabTypes.invitedClients)}
                        disabled={disabled && activeTab !== TransactionsTabTypes.invitedClients}
                    />
                )}
            </TabList>
            <TabContent>{!isDetailsLoaded ? <Preloader inProgress={true} /> : renderActiveTab()}</TabContent>
        </>
    );
};
