import classNames from "classnames";
import React from "react";
import { AmrFile } from '../../../../types/amr-pipeline/models/AmrFile';
import { OnHoverTooltip } from '../../../common';

interface DownloadDocumentProps {
    document: AmrFile;
    children: React.ReactNode;
    secondary?: boolean;
    onClick?: () => void;
    disabled?: boolean;
}

export default function DownloadDocument({
    document,
    children,
    secondary = false,
    onClick,
    disabled,
}: DownloadDocumentProps) {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (onClick && !disabled) {
            return onClick();
        }
    };

    return (
        <>
            <OnHoverTooltip overlay={document.name}>
                <a
                    onClick={handleClick}
                    href="/"
                    className={classNames('btn-link text-ellipsis btn-download-file', { secondary: secondary, disabled })}
                >
                    {children}
                </a>
            </OnHoverTooltip>

        </>
    );
}
