import { useContext } from "react";
import { amrPipelineDetailedActions } from "../../../../../../actions/amr-pipeline-detailed.actions";
import { constants } from "../../../../../../constants";
import { OriginatingTransaction } from "../../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { OriginatingTransactionDiff } from '../../../../../../types/amr-pipeline/models/OriginatingTransactionDiff';
import { Difference, DifferenceType } from '../../../../../../utils/differ/types';
import { FileIcon } from "../../../../../controls";
import DownloadDocument from "../../../../common/documents/DownloadDocument";
import { FormField } from "../../../../common/FormField";
import { SectionContent } from "../../../../common/section/SectionContent";
import TransactionContext from '../../../../transactionContext';
import { DifferenceField } from '../../DifferenceField';
import { DifferenceLabel, FieldDifferenceLabel } from '../../DifferenceLabel';
import IconSVG from '../../../../../../styles/svg-icons';
import { IntexLink } from "../../../../../common/IntexLink";
import { OnHoverTooltip } from '../../../../../common';
import { ImSubscriptionValue } from '../../../../subscription/ImSubscriptionValue';
import { ValueBlocker } from '../../../../../access/ValueBlocker';
import { hasArrangerAccess } from '../../../../../../utils/amr-pipeline.utils';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { useAppDispatch } from "../../../../../../effects/useAppDispatch";

interface Props {
    selectedTransaction: OriginatingTransaction;
    difference?: Difference<OriginatingTransactionDiff>;
}

export function Intex({ selectedTransaction, difference }: Props) {
    const dispatch = useAppDispatch();
    const { limitedAccess } = useContext(TransactionContext);

    if (limitedAccess) {
        return null;
    }

    const renderDocument = (transaction: OriginatingTransaction) => {
        if (!transaction.intexFile) {
            return constants.emptyPlaceholder;
        }

        return (
            <DownloadDocument
                onClick={
                    () => dispatch(
                        amrPipelineDetailedActions.downloadIntexFile(
                            transaction.dealReferenceName,
                            transaction.referenceName,
                            transaction.intexFile.name
                        )
                    )
                }
                document={transaction.intexFile}
                secondary
            >
                <FileIcon filename={transaction.intexFile.name} />
                {transaction.intexFile.name}
            </DownloadDocument>
        );
    };

    const renderPlainOrDifference = () => {
        // If no difference object, render as usual
        if (!difference) {
            return renderDocument(selectedTransaction);
        }

        const { intexFile } = difference;

        // If file was deleted, render just icon, file name and difference label
        if (intexFile?.referenceName?.type === DifferenceType.Removed) {
            const { name } = intexFile;

            return (
                <div className="file-name-wrap">
                    <div className="btn-download-file removed">
                        <IconSVG name="basket" width="16" height="16" />
                        {name?.previousValue}
                    </div>
                    <FieldDifferenceLabel difference={name} />
                </div>
            );
        }

        // Render file with download link and difference type (here is only added/updated)
        return (
            <div className="file-name-wrap">
                {renderDocument(selectedTransaction)}
                {intexFile?.referenceName?.type === DifferenceType.Updated
                    ? <DifferenceLabel differenceType={DifferenceType.Added} />
                    : <FieldDifferenceLabel difference={difference.intexFile?.referenceName} />
                }
            </div>
        )
    }

    return (
        <SectionContent
            title="Intex"
            className="data-item-intex"
        >
            <FormField title="Deal Name" className="data-item-row-full-height">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    {difference ? (
                        <DifferenceField difference={difference?.intexName}>
                            {selectedTransaction.intexName || constants.emptyPlaceholder}
                        </DifferenceField>
                    ) : (
                        <div className="name-with-link">
                            <div className="text-ellipsis name-box">
                                <OnHoverTooltip overlay={selectedTransaction.intexName}>
                                    {selectedTransaction.intexName || constants.emptyPlaceholder}
                                </OnHoverTooltip>
                            </div>
                            {selectedTransaction.intexName && (
                                <IntexLink
                                    positionId={selectedTransaction.intexName}
                                    text="Open Intex"
                                />
                            )}
                        </div>
                    )}
                </ImSubscriptionValue>
            </FormField>
            <FormField title="Password" className="data-item-row-full-height">
                <ImSubscriptionValue transaction={selectedTransaction}>
                    <DifferenceField difference={difference?.intexPassword}>
                        {selectedTransaction.intexPassword || constants.emptyPlaceholder}
                    </DifferenceField>
                </ImSubscriptionValue>
            </FormField>
            <FormField className="file-download-row data-item-row-full-height" title="File">
                <ValueBlocker
                    overrideRequiredFeatures={hasArrangerAccess(selectedTransaction)}
                    feature={SubscriptionFeature.IssuanceMonitorStandardizedDetailsTabs}
                >
                    {renderPlainOrDifference()}
                </ValueBlocker>
            </FormField>
        </SectionContent>
    );
}
