import { useEffect } from 'react';
import { getDealRegularDocumentTypes } from '../../../../../types/amr-pipeline/enums/DocumentType';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { SearchInput } from '../../../../common';
import { TabBody } from '../../../common/tabs/TabBody';
import { DocumentType } from '../../../../../types/amr-pipeline/enums/DocumentType';
import { useState } from 'react';
import { DocumentSection } from '../../../common/documents/DocumentSection';
import { amrPipelineDetailedActions, imDetailsDealsActions } from '../../../../../actions';
import { Transaction } from '../../../../../types/amr-pipeline/models/Transaction';
import { compareDates } from '../../../../../utils/compare.utils';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';
import { roles } from '../../../../../constants';
import { user } from '../../../../../user';

interface DocumentsProps {
    deal: Deal,
    transaction: Transaction;
}

export function Documents({ deal, transaction }: DocumentsProps) {
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const isAdminOrDataEntry = user.hasRoles(roles.Administrator, roles.DataEntry);


    useEffect(() => {
        dispatch(imDetailsDealsActions.resetDocumentsSearch());
    }, [dispatch])

    if (deal.documents == null) {
        return null;
    }

    const { documents: originalDocuments, filteredDocuments } = deal;

    const documents = (filteredDocuments
        ? filteredDocuments
        : originalDocuments
    ).sort((a, b) =>
        compareDates(
            b.executionDate ?? b.uploadTime,
            a.executionDate ?? a.uploadTime
        )
    ).filter(a => !isAdminOrDataEntry ? a.isPublic : a);


    const amrDocuments = documents.filter(d => d.transactionReferenceName != null);
    const dealDocuments = documents.filter(d => d.transactionReferenceName == null);
    const dealRegularDocuments = dealDocuments.filter(d => getDealRegularDocumentTypes().some(t => t === d.documentType));
    const distributionReports = dealDocuments.filter(d => d.documentType === DocumentType.distributionReport);
    const monthlyReports = dealDocuments.filter(d => d.documentType === DocumentType.monthlyReport);
    const otherDocuments = dealDocuments.filter(d => d.documentType === DocumentType.otherDocument);

    function handleSearchTermChange(value: string) {
        setSearchTerm(value);
        dispatch(
            imDetailsDealsActions.documentsSearchTermChange(
                value,
                deal.referenceName
            )
        );
    }

    function handleSearchTermClear() {
        setSearchTerm('');
        dispatch(
            imDetailsDealsActions.documentsSearchTermChange('', deal.referenceName)
        );
    }

    return (
        <TabBody className="tabs-data-padding component-tabs-deal-documents">
            <SearchInput
                onClear={handleSearchTermClear}
                value={searchTerm}
                onChange={handleSearchTermChange}
                placeholder="Search for documents"
            />
            <DocumentSection
                title="AMR Documents"
                dealReferenceName={deal.referenceName}
                transactionReferenceName={transaction.referenceName}
                items={amrDocuments}
                emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                downloadAllAction={() => dispatch(amrPipelineDetailedActions.downloadTransactionDocuments(deal.referenceName, transaction.referenceName, `${deal.ticker} AMR Documents.zip`))}
            />
            <DocumentSection
                title="Deal Documents"
                dealReferenceName={deal.referenceName}
                items={dealRegularDocuments}
                emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                downloadAllAction={() => dispatch(amrPipelineDetailedActions.downloadDealRegularDocuments(deal.referenceName, deal.ticker))}
            />
            <DocumentSection
                title="Distribution Reports"
                dealReferenceName={deal.referenceName}
                items={distributionReports}
                emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                downloadAllAction={() => dispatch(amrPipelineDetailedActions.downloadDealDocuments(deal.referenceName, deal.ticker, DocumentType.distributionReport))}
            />
            <DocumentSection
                title="Monthly Reports"
                dealReferenceName={deal.referenceName}
                items={monthlyReports}
                emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                downloadAllAction={() => dispatch(amrPipelineDetailedActions.downloadDealDocuments(deal.referenceName, deal.ticker, DocumentType.monthlyReport))}
            />
            <DocumentSection
                title="Other Documents"
                dealReferenceName={deal.referenceName}
                items={otherDocuments}
                emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                downloadAllAction={() => dispatch(amrPipelineDetailedActions.downloadDealDocuments(deal.referenceName, deal.ticker, DocumentType.otherDocument))}
            />
        </TabBody>
    );
}
