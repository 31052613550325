import { useDispatch } from "react-redux";
import { amrTransactionAnalyticsActions } from "../../../../../../../actions";
import { OriginatingTransactionDocument } from "../../../../../../../types/amr-pipeline/models/OriginatingTransactionDocument";
import { EmptyPlaceholder } from "../../../../../../common";
import DownloadDocument from "../../../../../common/documents/DownloadDocument";
import { DownloadedDocument } from '../../../../../../../types/amr-pipeline/models/AnalyticsSession';

interface Props {
    transactionReferenceName: string;
    dealReferenceName: string;
    documents: DownloadedDocument[];
    notDownloadableDocNames: string[];
}

export function DocsDownloadedTab({
    transactionReferenceName,
    dealReferenceName,
    documents,
    notDownloadableDocNames,
}: Props) {
    const dispatch = useDispatch();

    const getDocumentDownloadLink = (
        document: DownloadedDocument
    ) =>
        dispatch(
            amrTransactionAnalyticsActions.downloadDocument(
                dealReferenceName,
                transactionReferenceName,
                document
            )
        );

    if (!documents.length && !notDownloadableDocNames.length) {
        return <EmptyPlaceholder text="There are no downloaded docs yet." />
    }

    return (
        <ul className="list-bullet docs-downloaded-box">
            {notDownloadableDocNames.map((docName, index) => (
                <li key={docName + index} className="download-document-item">
                    {docName}
                </li>
            ))}
            {documents.map((d, index) => (
                <li key={d.referenceName + index} className="download-document-item">
                    <DownloadDocument
                        document={
                            {
                                referenceName: d.referenceName,
                                name: d.name,
                            } as OriginatingTransactionDocument
                        }
                        onClick={() => getDocumentDownloadLink(d)}
                        secondary
                    >
                        {d.name}
                    </DownloadDocument>
                </li>
            ))}
        </ul>
    );
}
