import { startCase } from "lodash";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { roles, routes } from "../../../../constants";
import { TransactionTypes } from "../../../../types/amr-pipeline/enums/TransactionType";
import { OriginatingTransaction } from "../../../../types/amr-pipeline/models/OriginatingTransaction";
import { OnHoverTooltip } from "../../../common";
import StatusLabel from "../../StatusLabel";
import Highlighter from "../../common/Highlighter";
import { transactionStatusTitles } from "../../../../types/amr-pipeline/enums/TransactionStatus";
import { NewDebutAmrLabel } from "../../common/NewDebutAmrLabel";
import { DealUpdatesPopover } from "../../aggregated/DealUpdatesPopover";
import { TransactionsTabTypes } from "../../types/TransactionsTabTypes";
import { PipelineDetailedTabTypes } from "../../types/PipelineDetailedTabTypes";
import { getTransactionDetailsUrl, hasIOIsAccess } from '../../../../utils/amr-pipeline.utils';
import { user } from "../../../../user";
import { ActionBlocker } from "../../../access/ActionBlocker";
import { SubscriptionFeature } from "../../../../types/billing/SubscriptionFeature";

interface Props {
    transaction: OriginatingTransaction;
    selected: boolean;
    searchTerm: string;
    activeTab: PipelineDetailedTabTypes;
}

export const OriginatingTransactionNavItem = ({ transaction, selected, searchTerm, activeTab }: Props) => {
    const history = useHistory();

    const ioisAccess = hasIOIsAccess();

    const { iois, classes } = transaction;
    const withIOIs = ioisAccess && (iois?.length || classes.some(c => c.numberOfIOIs));

    const typeText = TransactionTypes.find(
        (t) => t.value === transaction.type
    )?.text?.toLowerCase();
    const statusTitle = transactionStatusTitles[transaction.status];
    const isArrangersClient = user.hasSingleRole(roles.ArrangersClient);

    const renderLastUpdatedPopover = (transaction: OriginatingTransaction) => (
        <ActionBlocker
            feature={SubscriptionFeature.IssuanceMonitorFullAccess}
            overrideRequiredFeatures={isArrangersClient}
        >
            {blocked => {
                const {
                    referenceName,
                    dealReferenceName,
                    dealLegalName,
                    lastUpdated,
                    type,
                    status,
                    version,
                    dealUpdates,
                } = transaction;

                return (
                    <DealUpdatesPopover
                        referenceName={referenceName}
                        dealReferenceName={dealReferenceName}
                        dealLegalName={dealLegalName}
                        lastUpdated={lastUpdated}
                        type={type}
                        status={status}
                        version={version}
                        dealUpdates={dealUpdates}
                        disabled={blocked}
                        withOverlayPrefix
                        historyLinkTarget={selected ? '_self' : '_blank'}
                    />
                );
            }}
        </ActionBlocker>
    );

    const redirectToTransactionDetails = () => {
        history.push(getTransactionDetailsUrl(transaction, activeTab));
    }

    return (
        <div
            className={classNames('sidebar-nav-list-item sidebar-nav-list-item-extended no-expanded', {
                active: selected,
            })}
        >
            <div
                className="sidebar-nav-list-item-content"
                onClick={redirectToTransactionDetails}
            >
                <div className="flex-row">
                    <OnHoverTooltip overlay={transaction.dealLegalName}>
                        <Highlighter
                            searchWords={[searchTerm]}
                            autoEscape={true}
                            textToHighlight={transaction.dealLegalName}
                            className="title"
                        />
                    </OnHoverTooltip>
                    <div className="flex-item-right">
                        <NewDebutAmrLabel transaction={transaction} />
                        {withIOIs && (
                            <button
                                onClick={e => {
                                    e.stopPropagation();
                                    history.push(
                                        routes.transactionDetailUrl(
                                            transaction.referenceName,
                                            transaction.dealReferenceName,
                                            TransactionsTabTypes.IOIs,
                                        ),
                                    );
                                }}
                                className="label-status label-status-iois"
                            >
                                IOIs
                            </button>
                        )}
                    </div>
                </div>
                <div className="flex-row">
                    <OnHoverTooltip
                        wrapperClass="flex-none item-info-tooltip"
                        overlay={`Transaction Status: ${statusTitle}`}
                    >
                        <StatusLabel status={statusTitle} />
                    </OnHoverTooltip>

                    <OnHoverTooltip
                        wrapperClass="flex-none item-info-tooltip"
                        overlay={`Transaction Type: ${startCase(transaction.type)}`}
                    >
                        <div className={`label-status label-status-type-${typeText}`}>
                            {TransactionTypes.find(t => t.value === transaction.type)?.text}
                        </div>
                    </OnHoverTooltip>
                    <div className="item-info-tooltip text-right">{renderLastUpdatedPopover(transaction)}</div>
                </div>
            </div>
        </div>
    );
};
